import * as React from 'react'

import { ApolloProvider } from '@apollo/client'
import { getClient } from '@local/api'
import { useFeature } from '@toasttab/ec-features'
import { BrowserRouter } from 'react-router-dom'
import { useUserCanViewChatbot } from '@local/user-util'
import { IntercomChat } from '@local/intercom-chat'

export function App() {
  const [client] = React.useState(() => getClient())
  const isPayrollChatbot = useFeature('ec-pcard-payroll-chatbot', false)
  const userCanViewChatbot = useUserCanViewChatbot()

  return isPayrollChatbot && userCanViewChatbot ? (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <IntercomChat />
      </BrowserRouter>
    </ApolloProvider>
  ) : null
}
